import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { FormattedTime } from "../../components/Date";
import { GenderIcon } from "../../components/GenderIcon";
import { RootState } from "../../store/Reducer";
import { GENDER } from "../../store/Customer";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { CustomerMap } from "../../components/CustomerMap";

interface Props {}

const MAX_TABLE_ITEMS = 10;

export const NewUsers: FunctionComponent<Props> = () => {
  const stream = useSelector((state: RootState) => state.customers.stream);
  const points = useSelector((state: RootState) => state.customers.points);
  if (!stream || stream.length < 1) {
    return <div>Väntar på data</div>;
  }
  const streamCopy = [...stream]
    .slice(Math.max(stream.length - MAX_TABLE_ITEMS, 0))
    .reverse();
  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-around", height: '100%' }}>
        <fieldset style={{width: "50%", border: 0, marginRight: '20px'}}>
          <CustomerMap points={points} />
        </fieldset>
        <fieldset style={{ minWidth: "50%" }}>
        <h1>Nya kunder idag</h1>
          <div className="giant-number">
            {stream[stream.length - 1].todayCount}
          </div>
          <h2>Senaste registreringar</h2>
          <table style={{ width: "100%" }}>
            <tbody>
              <TransitionGroup component={null}>
                {streamCopy.map(customer => (
                  <CSSTransition
                    key={customer.time}
                    timeout={1000}
                    classNames="animated-row"
                    mountOnEnter
                    unmountOnExit
                  >
                    <tr key={customer.time}>
                      <td>
                        <FormattedTime date={customer.time} />
                      </td>
                      <td>
                        <GenderIcon gender={customer.gender} />
                      </td>
                      <td>
                        {customer.gender !== GENDER.CORPORATION
                          ? `${customer.age} år`
                          : "-"}
                      </td>
                      <td>{customer.city}</td>
                    </tr>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </tbody>
          </table>
        </fieldset>
      </div>
    </div>
  );
};
