import React from "react";
import { legacy_createStore as createStore } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { SenderPage } from "./sender/SenderPage";
import { Aum } from "./receiver/Aum/Aum";
import { ReceiverPage } from "./receiver/ReceiverPage";
import { rootReducer } from "./store/Reducer";
import "./App.css";
import { create } from "domain";

const store = createStore(rootReducer, (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__());

const App: React.FC = () => (
  <Provider store={store}>
    <Router>
      <Route path="/" exact component={SenderPage} />
      <Route path="/receiver" exact component={ReceiverPage} />
    </Router>
  </Provider>
);

export default App;
