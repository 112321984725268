import { combineReducers } from 'redux';
import { loginReducer, LoginState } from './Login';
import { customerReducer, CustomerState } from './Customer';
import { depositReducer, DepositState } from './Deposit';
import { ModalState, modalReducer } from './Modal'
import { aumReducer, AumState } from './Aum';

export interface RootState {
  logins: LoginState;
  customers: CustomerState;
  deposits: DepositState;
  aum: AumState;
  modal: ModalState
}

export const rootReducer = combineReducers({
  logins: loginReducer,
  customers: customerReducer,
  deposits: depositReducer,
  aum: aumReducer,
  modal: modalReducer
});