import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../store/Reducer";
import { FormattedNumber } from "../../components/FormattedNumber";

export const Aum: FunctionComponent = () => {
  const stream = useSelector((state: RootState) => state.aum.stream);
  console.log("hmm something is wrong", stream)
  if (!stream || stream.length === 0) {
    return <div>Väntar på data</div>;
  }
  
  return (
    <div>
      <h1
        style={{
          fontSize: "60px",
        }}
      >
        AUM
      </h1>
      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <div>
          <fieldset>
            
            <div className="giant-number" style={{ fontSize: "80px" }}>
              <FormattedNumber
                number={stream[stream.length - 1].totalAumInSek}
              />{" "}
              kr
            </div>
          </fieldset>
        </div>
      </div>
    </div>
  );
};
