import { Dispatch, AnyAction } from "redux";
const UPDATE_AUM_STREAM = "UPDATE_AUM_STREAM";

export interface AumStream {
    totalAumInSek: number;
}

interface AumAction {
    type: typeof UPDATE_AUM_STREAM;
    stream: AumStream;
  }

export interface AumState {
    stream: AumStream[];
}
  
const initialState: AumState = {
    stream: []
};

export function updateAumStream(dispatch: Dispatch<AnyAction>) {
    return (newStream: AumStream): void => {
      dispatch({
        type: UPDATE_AUM_STREAM,
        stream: newStream,
      });
    };
  }

  export function aumReducer(state = initialState, action: AumAction) {
    
    if (!state) {
      return state;
    }
    switch (action.type) {
      case UPDATE_AUM_STREAM:
        return { ...state, stream: [action.stream] };
      default:
        return state;
    }
  }

