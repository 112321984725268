import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { FormattedTime } from "../../components/Date";
import { GenderIcon } from "../../components/GenderIcon";
import { RootState } from "../../store/Reducer";
import { CustomerMap } from '../../components/CustomerMap';

interface Props {}

const MAX_TABLE_ITEMS = 10;

export const Logins: FunctionComponent<Props> = () => {
  const stream = useSelector((state: RootState) => state.logins.stream);
  const points = useSelector((state: RootState) => state.logins.points);
  if (!stream || stream.length < 1) {
    return <div>Väntar på data</div>;
  }
  const streamCopy = [...stream]
    .slice(Math.max(stream.length - MAX_TABLE_ITEMS, 0))
    .reverse();
  return (
    <div style= {{ marginLeft: '-20px', marginRight: '-20px'}} >
      <div style={{ display: "flex", justifyContent: "space-around", height: '100%' }}>
        <fieldset style={{width: "50%", border: 0, marginRight: '20px'}}>
          <CustomerMap points={points} />
        </fieldset>
        <fieldset style={{ minWidth: "50%" }}>
        <h1>Inloggningar idag</h1>
          <div className="giant-number">
            {stream[stream.length - 1].todayCount}
          </div>
          <h2>Senaste inloggningarna</h2>
          <table style={{ width: "100%" }}>
            <tbody>
              <TransitionGroup component={null}>
                {streamCopy.map(login => (
                  <CSSTransition
                    key={login.time}
                    timeout={1000}
                    classNames="animated-row"
                    mountOnEnter
                    unmountOnExit
                  >
                    <tr>
                      <td>
                        <FormattedTime date={login.time} />
                      </td>
                      <td>
                        <GenderIcon gender={login.gender} />
                      </td>
                      <td>{login.age} år</td>
                      <td>{login.city}</td>
                    </tr>
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </tbody>
          </table>
        </fieldset>
      </div>
    </div>
  );
};
